import { createContext, useContext, ReactNode } from "react";

import { AuthService } from "services/auth";

interface AuthProviderProps {
  roles: string[];
  children: ReactNode;
}

const AuthContext = createContext<AuthProviderProps | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { resourceAccess } = AuthService;
  const resourceAccessData = resourceAccess() || {};
  const roles = resourceAccessData["ws-people"]?.roles || [];

  return (
    <AuthContext.Provider value={{ roles, children }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
