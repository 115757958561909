import { useState, createContext, useContext, ReactNode } from "react";

interface Notif {
  id: string;
  title: string;
  created_at: string;
  read_at: string;
}

interface NotificationsContextProps {
  selectedNotification: Notif | null;
  setSelectedNotification: (notification: Notif | null) => void;
}

const NotificationsContext = createContext<
  NotificationsContextProps | undefined
>(undefined);
export const NotificationProvider = ({ children }: { children: ReactNode }) => {
  const [selectedNotification, setSelectedNotification] =
    useState<Notif | null>(null);

  return (
    <NotificationsContext.Provider
      value={{ selectedNotification, setSelectedNotification }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotifications = () => {
  const context = useContext(NotificationsContext);
  if (!context) {
    throw new Error(
      "useNotifications must be used within a NotificationProvider",
    );
  }
  return context;
};
