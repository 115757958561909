export const urlConfig = {
  signupURL: "https://signup.organogram.app",
  apiUrl: "https://api.people.organogram.app/api/v1/",
  aiApiUrl: "https://ai.organogram.app/api/v1",
  mySpaceUrl: "https://myspace.organogram.app",
  notificationsUrl: "https://notifications.organogram.app/api/v1/",
  payrollUrl: "https://payroll.organogram.app",
  hrUrl: "https://people.organogram.app",
  adminUrl: "https://admin.organogram.app",
  workspaceTrends: "https://trends.organogram.app/api/v1/trends",
};

export const userRoles = {
  employee_manager: "people.employee-manager",
  reports_viewer: "people.report-viewer",
  company_manager: "people.company-manager",
  medical_info_viewer: "people.medical-info-viewer",
  personal_info_viewer: "people.personal-info-viewer",
  announcement_editor: "people.announcement-editor",
};
