import { lazy } from "react";
import { Navigate } from "react-router-dom";

import LayoutSuspense from "./shared/LayoutSuspense";
import ModuleSuspense from "./shared/ModuleSuspense";
import { ProtectedRoute } from "components/ProtectedRoute";
import { userRoles } from "config";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
const AuthLayout = lazy(() => import("./layouts/Auth"));
const DashboardLayout = lazy(() => import("./layouts/Dashboard"));

// Auth components
const SignIn = lazy(() => import("./pages/auth"));

// Dashboard components
const Dashboard = lazy(() => import("./pages/modules/dashboard"));

// notifications
const Notifications = lazy(() => import("./pages/modules/notifications"));

// Reports
const Reports = lazy(() => import("./pages/modules/reports"));
const EmployeeReports = lazy(
  () => import("./pages/modules/reports/pages/employee-reports"),
);
const HistoryReports = lazy(
  () => import("./pages/modules/reports/pages/leave-reports"),
);
const DailySnapShot = lazy(
  () => import("./pages/modules/reports/pages/leave-daily-snapshot"),
);
const LeaveSummary = lazy(
  () => import("./pages/modules/reports/pages/leave-summary"),
);
const ContractorReports = lazy(
  () => import("./pages/modules/reports/pages/contractor-reports"),
);
const ConfirmationReports = lazy(
  () => import("./pages/modules/reports/pages/confirmation-reports"),
);
const TerminationTrend = lazy(
  () => import("./pages/modules/reports/pages/termination-trend"),
);
const BradfordFactor = lazy(
  () => import("./pages/modules/reports/pages/braford-factor"),
);
const Report = lazy(() => import("./pages/modules/reports/pages/report"));
const Metrics = lazy(() => import("./pages/modules/reports/pages/metrics"));
const AggregateReports = lazy(
  () => import("./pages/modules/reports/pages/leave-aggregates"),
);

// Profile
const MyProfile = lazy(() => import("./pages/modules/profile/my-profile"));
const FeedbackModule = lazy(() => import("./pages/modules/profile/feedback"));
const Suggestions = lazy(() => import("./pages/modules/profile/suggestion"));
const LeaveModule = lazy(() => import("./pages/modules/profile/leave"));
const ProfileAbsences = lazy(() => import("./pages/modules/profile/absences"));

const ProfileIncidentsModule = lazy(
  () => import("./pages/modules/profile/incidents"),
);

// Manager
const Incident = lazy(() => import("./pages/modules/manager/incidents"));
const LeavePlans = lazy(() => import("./pages/modules/manager/leave-plans"));
const ManagerAbsence = lazy(() => import("./pages/modules/manager/absence"));

// Settings
const Settings = lazy(() => import("./pages/modules/settings"));

// Company components
const AnnouncementModule = lazy(
  () => import("./pages/modules/team/announcement"),
);

const TeamLeaveModule = lazy(() => import("./pages/modules/team/leave"));

const AnnouncementView = lazy(
  () => import("./pages/modules/team/announcement/view"),
);
const AnnouncementCreation = lazy(
  () => import("./pages/modules/team/announcement/create/index"),
);
const CompanyDocuments = lazy(() => import("./pages/modules/team/documents"));
const Employees = lazy(() => import("./pages/modules/team/employees"));
const EmployeeDetails = lazy(
  () => import("./pages/modules/team/employees/Profile"),
);
const EmployeeAbsence = lazy(() => import("./pages/modules/team/absence"));
const Incidents = lazy(() => import("./pages/modules/team/incident"));
const Onboarding = lazy(
  () => import("./pages/modules/team/employees/Profile/components/onboarding"),
);
const Outboarding = lazy(
  () => import("./pages/modules/team/termination/outboarding"),
);
const Positions = lazy(() => import("./pages/modules/team/position"));
const Terminations = lazy(() => import("./pages/modules/team/termination"));
const TerminationView = lazy(
  () => import("./pages/modules/team/termination/view"),
);
const Unit = lazy(() => import("./pages/modules/team/unit"));
const TeamSuggestions = lazy(() => import("./pages/modules/team/suggestions"));
const TeamOnboardings = lazy(() => import("./pages/modules/team/onboardings"));

// Recruitment
const Recruitment = lazy(() => import("./pages/modules/recruitment"));

// Careers
const Careers = lazy(() => import("./pages/modules/careers"));

const {
  reports_viewer,
  employee_manager,
  company_manager,
  announcement_editor,
  personal_info_viewer,
  medical_info_viewer,
} = userRoles;

export const authenticated_routes = [
  {
    path: "/",
    element: (
      <LayoutSuspense>
        <DashboardLayout />
      </LayoutSuspense>
    ),
    children: [
      {
        path: "/dashboard",
        element: (
          <ModuleSuspense>
            <Dashboard />
          </ModuleSuspense>
        ),
      },

      {
        path: "/profile/feedback",
        element: (
          <ModuleSuspense>
            <FeedbackModule />
          </ModuleSuspense>
        ),
      },
      {
        path: "/profile/suggestions",
        element: (
          <ModuleSuspense>
            <Suggestions />
          </ModuleSuspense>
        ),
      },
      {
        path: "/profile/leave",
        element: (
          <ModuleSuspense>
            <LeaveModule />
          </ModuleSuspense>
        ),
      },
      {
        path: "/profile/absences",
        element: (
          <ModuleSuspense>
            <ProfileAbsences />
          </ModuleSuspense>
        ),
      },
      {
        path: "/profile/incidents",
        element: (
          <ModuleSuspense>
            <ProfileIncidentsModule />
          </ModuleSuspense>
        ),
      },
      {
        path: "/profile/my-profile",
        element: (
          <ModuleSuspense>
            <MyProfile />
          </ModuleSuspense>
        ),
      },
      {
        path: "/settings",
        element: (
          <ModuleSuspense>
            <ProtectedRoute allowedRoles={[company_manager]}>
              <Settings />
            </ProtectedRoute>
          </ModuleSuspense>
        ),
      },
      {
        path: "/outboarding",
        element: (
          <ModuleSuspense>
            <Outboarding />
          </ModuleSuspense>
        ),
      },
      {
        path: "/report/:id",
        element: (
          <ModuleSuspense>
            <ProtectedRoute allowedRoles={[reports_viewer]}>
              <Report />
            </ProtectedRoute>
          </ModuleSuspense>
        ),
      },
      {
        path: "/metrics/:id",
        element: (
          <ModuleSuspense>
            <ProtectedRoute allowedRoles={[reports_viewer]}>
              <Metrics />
            </ProtectedRoute>
          </ModuleSuspense>
        ),
      },

      { path: "/", element: <Navigate to="/dashboard" /> },
      {
        path: "*",
        element: <Navigate to="/dashboard" />,
      },
    ],
  },
  {
    path: "/team",
    element: (
      <LayoutSuspense>
        <DashboardLayout />
      </LayoutSuspense>
    ),
    children: [
      {
        path: "employees",
        element: (
          <ModuleSuspense>
            <ProtectedRoute
              allowedRoles={[
                employee_manager,
                personal_info_viewer,
                medical_info_viewer,
              ]}
            >
              <Employees />
            </ProtectedRoute>
          </ModuleSuspense>
        ),
      },
      {
        path: "/team/employees/:id",
        element: (
          <ModuleSuspense>
            <EmployeeDetails />
          </ModuleSuspense>
        ),
      },

      {
        path: "/team/employees/onboarding",
        element: (
          <ModuleSuspense>
            <Onboarding />
          </ModuleSuspense>
        ),
      },
      {
        path: "terminations",
        element: (
          <ModuleSuspense>
            <ProtectedRoute allowedRoles={[employee_manager]}>
              <Terminations />
            </ProtectedRoute>
          </ModuleSuspense>
        ),
      },
      {
        path: "absences",
        element: (
          <ModuleSuspense>
            <ProtectedRoute allowedRoles={[employee_manager]}>
              <EmployeeAbsence />
            </ProtectedRoute>
          </ModuleSuspense>
        ),
      },
      {
        path: "/team/terminations/:id",
        element: (
          <ModuleSuspense>
            <ProtectedRoute allowedRoles={[employee_manager]}>
              <TerminationView />
            </ProtectedRoute>
          </ModuleSuspense>
        ),
      },
      {
        path: "incidents",
        element: (
          <ModuleSuspense>
            <ProtectedRoute allowedRoles={[employee_manager]}>
              <Incidents />
            </ProtectedRoute>
          </ModuleSuspense>
        ),
      },
      {
        path: "positions",
        element: (
          <ModuleSuspense>
            <ProtectedRoute allowedRoles={[employee_manager]}>
              <Positions />
            </ProtectedRoute>
          </ModuleSuspense>
        ),
      },
      {
        path: "units",
        element: (
          <ModuleSuspense>
            <Unit />
          </ModuleSuspense>
        ),
      },
      {
        path: "documents",
        element: (
          <ModuleSuspense>
            <CompanyDocuments />
          </ModuleSuspense>
        ),
      },
      {
        path: "announcements",
        element: (
          <ModuleSuspense>
            <AnnouncementModule />
          </ModuleSuspense>
        ),
      },
      {
        path: "onboardings",
        element: (
          <ModuleSuspense>
            <ProtectedRoute allowedRoles={[employee_manager]}>
              <TeamOnboardings />
            </ProtectedRoute>
          </ModuleSuspense>
        ),
      },
      {
        path: "/team/announcements/:id",
        element: (
          <ModuleSuspense>
            <AnnouncementView />
          </ModuleSuspense>
        ),
      },
      {
        path: "/team/announcements/:id/edit",
        element: (
          <ModuleSuspense>
            <ProtectedRoute allowedRoles={[announcement_editor]}>
              <AnnouncementCreation />
            </ProtectedRoute>
          </ModuleSuspense>
        ),
      },
      {
        path: "/team/announcements/create",
        element: (
          <ModuleSuspense>
            <ProtectedRoute allowedRoles={[announcement_editor]}>
              <AnnouncementCreation />
            </ProtectedRoute>
          </ModuleSuspense>
        ),
      },
      {
        path: "/team/leave",
        element: (
          <ModuleSuspense>
            <ProtectedRoute allowedRoles={[employee_manager]}>
              <TeamLeaveModule />
            </ProtectedRoute>
          </ModuleSuspense>
        ),
      },
      {
        path: "/team/suggestions",
        element: (
          <ModuleSuspense>
            <ProtectedRoute allowedRoles={[employee_manager]}>
              <TeamSuggestions />
            </ProtectedRoute>
          </ModuleSuspense>
        ),
      },
    ],
  },
  {
    path: "/notifications",
    element: (
      <LayoutSuspense>
        <DashboardLayout />
      </LayoutSuspense>
    ),

    children: [
      {
        path: "/notifications",
        element: (
          <ModuleSuspense>
            <Notifications />
          </ModuleSuspense>
        ),
      },
    ],
  },
  {
    path: "/reports",
    element: (
      <LayoutSuspense>
        <DashboardLayout />
      </LayoutSuspense>
    ),
    children: [
      {
        path: "/reports",
        element: (
          <ModuleSuspense>
            <ProtectedRoute allowedRoles={[reports_viewer]}>
              <Reports />
            </ProtectedRoute>
          </ModuleSuspense>
        ),
      },
      {
        path: "/reports/employee-reports",
        element: (
          <ModuleSuspense>
            <ProtectedRoute allowedRoles={[reports_viewer]}>
              <EmployeeReports />
            </ProtectedRoute>
          </ModuleSuspense>
        ),
      },
      {
        path: "/reports/contractor-reports",
        element: (
          <ModuleSuspense>
            <ProtectedRoute allowedRoles={[reports_viewer]}>
              <ContractorReports />
            </ProtectedRoute>
          </ModuleSuspense>
        ),
      },
      {
        path: "/reports/leave-aggregates",
        element: (
          <ModuleSuspense>
            <ProtectedRoute allowedRoles={[reports_viewer]}>
              <AggregateReports />
            </ProtectedRoute>
          </ModuleSuspense>
        ),
      },
      {
        path: "/reports/confirmation-reports",
        element: (
          <ModuleSuspense>
            <ProtectedRoute allowedRoles={[reports_viewer]}>
              <ConfirmationReports />
            </ProtectedRoute>
          </ModuleSuspense>
        ),
      },
      {
        path: "/reports/bradford-factor",
        element: (
          <ModuleSuspense>
            <ProtectedRoute allowedRoles={[reports_viewer]}>
              <BradfordFactor />
            </ProtectedRoute>
          </ModuleSuspense>
        ),
      },
      {
        path: "/reports/termination-trend",
        element: (
          <ModuleSuspense>
            <ProtectedRoute allowedRoles={[reports_viewer]}>
              <TerminationTrend />
            </ProtectedRoute>
          </ModuleSuspense>
        ),
      },
      {
        path: "/reports/leave-history",
        element: (
          <ModuleSuspense>
            <ProtectedRoute allowedRoles={[reports_viewer]}>
              <HistoryReports />
            </ProtectedRoute>
          </ModuleSuspense>
        ),
      },
      {
        path: "/reports/leave-daily-snapshot",
        element: (
          <ModuleSuspense>
            <ProtectedRoute allowedRoles={[reports_viewer]}>
              <DailySnapShot />
            </ProtectedRoute>
          </ModuleSuspense>
        ),
      },
      {
        path: "/reports/leave-summary",
        element: (
          <ModuleSuspense>
            <ProtectedRoute allowedRoles={[reports_viewer]}>
              <LeaveSummary />
            </ProtectedRoute>
          </ModuleSuspense>
        ),
      },
    ],
  },
  {
    path: "/manager",
    element: (
      <LayoutSuspense>
        <DashboardLayout />
      </LayoutSuspense>
    ),
    children: [
      {
        path: "incidents",
        element: (
          <ModuleSuspense>
            <Incident />
          </ModuleSuspense>
        ),
      },
      {
        path: "leave-plans",
        element: (
          <ModuleSuspense>
            <LeavePlans />
          </ModuleSuspense>
        ),
      },
      {
        path: "absences",
        element: (
          <ModuleSuspense>
            <ManagerAbsence />
          </ModuleSuspense>
        ),
      },
    ],
  },
  {
    path: "/",
    element: (
      <LayoutSuspense>
        <DashboardLayout />
      </LayoutSuspense>
    ),
    children: [
      {
        path: "recruitment",
        element: (
          <ModuleSuspense>
            <Recruitment />
          </ModuleSuspense>
        ),
      },
    ],
  },
  {
    path: "/",
    element: (
      <LayoutSuspense>
        <DashboardLayout />
      </LayoutSuspense>
    ),
    children: [
      {
        path: "careers",
        element: (
          <ModuleSuspense>
            <Careers />
          </ModuleSuspense>
        ),
      },
    ],
  },
];

export const unauthenticated_routes = [
  {
    path: "/",
    element: (
      <LayoutSuspense>
        <AuthLayout />
      </LayoutSuspense>
    ),
    children: [
      {
        path: "/sign-in",
        element: (
          <ModuleSuspense>
            <SignIn />
          </ModuleSuspense>
        ),
      },
      {
        path: "/",
        element: <Navigate to="/sign-in" />,
      },
      {
        path: "*",
        element: <Navigate to="/sign-in" />,
      },
    ],
  },
];
