import { ReactNode } from "react";
import { Navigate } from "react-router-dom";

import { useAuth } from "contexts/authorization";

interface ProtectedRouteProps {
  allowedRoles: string[];
  redirectTo: string;
  children: ReactNode;
}

export const ProtectedRoute = ({
  allowedRoles,
  redirectTo = "/",
  children,
}: ProtectedRouteProps) => {
  const { roles } = useAuth();

  const hasAccess = roles.some((role) => allowedRoles.includes(role));

  if (!hasAccess) {
    return <Navigate to={redirectTo} replace />;
  }

  return <>{children}</>;
};
