import Keycloak from "keycloak-js";

export const keycloak = new Keycloak("/keycloak.json");

const initKeycloak = (onAuthenticatedCallback) => {
  keycloak
    .init({
      onLoad: "check-sso",
      silentCheckSsoRedirectUri:
        window.location.origin + "/silent-check-sso.html",
      pkceMethod: "S256",
    })
    .then((authenticated) => {
      if (!authenticated) {
        onAuthenticatedCallback();
      }
      onAuthenticatedCallback();
    })
    .catch(console.error);
};

const resourceAccess = () => keycloak.tokenParsed?.resource_access;

const clearToken = keycloak.clearToken;
const doLogin = keycloak.login;

const doLogout = keycloak.logout;

const getDomain = () => {
  const getDomainFromUsername = () => keycloak.tokenParsed?.preferred_username;
  const username = getDomainFromUsername();
  const domain = username.split("@")[1];
  return domain;
};

const getRefreshToken = () => keycloak.refreshToken;
const getToken = () => keycloak.token;
const getUsername = () => keycloak.tokenParsed?.given_name;
const getUserDetails = () => {
  let user_id;
  if (keycloak.tokenParsed) {
    user_id = keycloak.tokenParsed["user-id"];
  }
  const user_details = {
    id: user_id,
    full_name: keycloak.tokenParsed?.name,
    given_name: keycloak.tokenParsed?.given_name,
  };

  return user_details;
};

const isLoggedIn = () => !!keycloak.token;

const updateToken = (successCallback) =>
  keycloak.updateToken(5).then(successCallback).catch(doLogin);

const userFullName = () => keycloak.tokenParsed?.name;

export const AuthService = {
  clearToken,
  doLogin,
  doLogout,
  isLoggedIn,
  initKeycloak,
  getRefreshToken,
  getToken,
  getUsername,
  getUserDetails,
  resourceAccess,
  updateToken,
  userFullName,
  getDomain,
};
