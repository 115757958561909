import axios from "axios";
import { urlConfig } from "../config";
import { apiGet, apiPatch, apiDelete, apiPost, apiPut } from "./_http";

const { apiUrl } = urlConfig;
const apiEndpoint = `${apiUrl}organizations`;

export const get = async (options = {}) => {
  return await apiGet(`${apiEndpoint}`, { ...options });
};

export const updateOnboardingSteps = async (options = {}) => {
  return await apiPatch(`${apiEndpoint}/onboarding-steps`, { ...options });
};

// exit reasons
export const createExitReason = async (options = {}) => {
  return await apiPost(`${apiEndpoint}/exit-reasons`, { ...options });
};

export const deleteExitReasonById = async (id, options = {}) => {
  return await apiDelete(`${apiEndpoint}/exit-reasons/${id}`, { ...options });
};

export const updateExitReasonById = async (id, options = {}) => {
  return await apiPut(`${apiEndpoint}/exit-reasons/${id}`, { ...options });
};

// incident Type

export const createIncidentType = async (options = {}) => {
  return await apiPost(`${apiEndpoint}/incident-types`, { ...options });
};

export const updateIncidentType = async (id, options = {}) => {
  return await apiPut(`${apiEndpoint}/incident-types/${id}`, { ...options });
};

export const deleteIncidentType = async (id) => {
  return await apiDelete(`${apiEndpoint}/incident-types/${id}`);
};

// mission
export const updateMission = async (options = {}) => {
  return await apiPatch(`${apiEndpoint}/mission`, { ...options });
};

export const updateVision = async (options = {}) => {
  return await apiPatch(`${apiEndpoint}/vision`, { ...options });
};

// values
export const addValue = async (options = {}) => {
  return await apiPost(`${apiEndpoint}/values`, { ...options });
};

export const editValue = async (id, options = {}) => {
  return await apiPut(`${apiEndpoint}/values/${id}`, { ...options });
};

export const deleteValue = async (id) => {
  return await apiDelete(`${apiEndpoint}/values/${id}`);
};

// Documents
export const getSignedUrl = async (options = {}) => {
  return await apiPost(`${apiEndpoint}/documents/upload-signed-url`, {
    ...options,
  });
};

export const uploadDocument = async (url, file, config) => {
  try {
    const request = await axios.put(url, file, config);
    return request;
  } catch (error) {
    return error.response?.data;
  }
};

export const createDocument = async (options = {}) => {
  return await apiPost(`${apiEndpoint}/documents`, { ...options });
};

export const deleteDocument = async (options = {}) => {
  const { document_id } = options;
  return await apiDelete(`${apiEndpoint}/documents/${document_id}`, {
    ...options,
  });
};

export const companyDownloadDocument = async (options = {}) => {
  const { document_id } = options;
  return await apiGet(
    `${apiEndpoint}/documents/download-signed-url/${document_id}`,
    { ...options },
  );
};

export const uploadLogo = async (options = {}) => {
  return await apiPatch(`${apiEndpoint}/logo`, { ...options });
};

export const updateProfile = async (options = {}) => {
  return await apiPatch(`${apiEndpoint}/profile`, { ...options });
};

export const updateAddress = async (options = {}) => {
  return await apiPatch(`${apiEndpoint}/address`, { ...options });
};

export const getLogoSignature = async () => {
  return await apiGet(`${apiEndpoint}/logo-signature`);
};

export const getTeamOnboarding = async () => {
  return await apiGet(`${apiEndpoint}/onboardings`);
};

export const addUrl = async (options = {}) => {
  return await apiPost(`${apiEndpoint}/urls`, { ...options });
};

export const updateUrlById = async (urlId, options = {}) => {
  return await apiPut(`${apiEndpoint}/urls/${urlId}`, { ...options });
};
export const deleteUrl = async (urlId, options = {}) => {
  return await apiDelete(`${apiEndpoint}/urls/${urlId}`, { ...options });
};
